//
// frappe-gantt.scss
//

@import '../../../node_modules/frappe-gantt/src/styles/gantt.css';

.gantt-container {
  border-radius: 0;
  // we always render whole Gantt vertically, so hide vertical scrollbar
  overflow-y: hidden;
}

$gantt-bg: #37404a;
$gantt-light-bg: #464f5b;

:root {
  --g-arrow-color: var(--ct-warning);
  --g-bar-color: #{$gantt-light-bg};
  --g-bar-border: #{$gantt-light-bg};
  --g-tick-color-thick: #{$gantt-light-bg};
  --g-tick-color: #{$gantt-light-bg};
  --g-actions-background: #6c757d;
  --g-border-color: #{$gantt-light-bg};
  --g-text-muted: #aab8c5;
  --g-text-dark: #fff;
  --g-progress-color: var(--ct-primary);
  --g-header-background: #{$gantt-light-bg};
  --g-row-color: #{$gantt-bg};
  --g-today-highlight: #fa5c7c;
  --g-popup-actions: #{$gantt-light-bg};
  --g-weekend-highlight-color: #{darken($gantt-bg, 1%)};
}

.gantt-container {
  .current-upper {
    background: var(--g-header-background);
  }

  .popup-wrapper {
    background: #{lighten($gantt-light-bg, 4%)};
  }
}
