// custom/components/_avatar.scss

// enlarge xs avatar slightly
// set min-width to avatars to fix text avatar to have ellipse shape
.avatar-xs {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
}

.avatar-sm {
  min-width: 3rem;
}

.avatar-md {
  min-width: 4.5rem;
}

.avatar-lg {
  min-width: 6rem;
}

.avatar-xl {
  min-width: 7.5rem;
}


// custom/plugins/_dropzone.scss
.dropzone.drag-active {
  border-color: $primary;
}

// PERP additions

.hoverunderline:hover {
  text-decoration: underline;
}

.icon-sm {
  font-size: 2rem !important;
}

.file-icon {
  font-size: 2rem !important;
}

// remove arrows from number inputs
// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

.form-control-sm.padding-sm {
  padding: 0.28rem;
}

// Fix for z-index, maybe this should be in updated Hyper theme?
.input-group {
  > .form-control:focus,
  > .form-select:focus,
  > .form-floating:focus-within {
    z-index: 3;
  }
}

// Change apexcharts (donut) tooltip text in dark mode
body[data-layout-color='dark'] {
  .apex-charts-donut
  .apexcharts-tooltip-title,
  .apex-charts-donut
  .apexcharts-tooltip-text {
    color: white;
  }
}

@media print {
  .no-print {
    display: none;
  }
}

// fix for pagination in dark mode
.page-link {
  background-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

/* Progress bar in Kanban project table */
.kanban-progress {
  height: $card-border-radius;
  margin-bottom: -$card-border-radius;
  border-radius: 0;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

/* Inverted colors for image */
img.inverted {
  filter: invert(100%);
}
